<template>
  <div
    :style="[vendor.bannerURL ? { backgroundImage: 'url(' + vendor.bannerURL + ')' } : '']"
    class="Eventos col-12"
    v-if="vendor && design "
  >
    <NavBar :isButton="true" back="home"></NavBar>
    <HeaderEvent name="Eventos & Experiencias"></HeaderEvent>
    <div class="Eventos-container">
      <div class="container" v-if="designEvent">
        <div class="Eventos-container" v-if="ready_events">
          <div class="Eventos-title" style="align-items: center;">
            <i class="fa fa-circle"></i>
            <h4>{{ $t('eventsView.text1')}}</h4>
            <i class="fa fa-circle"></i>
          </div>
          <div class="Eventos-body" v-if="ready_events">
            <CardEvent :eventData="event" :key="index" v-for="(event, index) in events"></CardEvent>
          </div>
        </div>
        <div
          :style="{ borderColor:  designEvent.colorHeadEvent }"
          class="Eventos-containerCardExperience card"
          v-if="ready_experiences"
        >
          <div
            :style="{ backgroundColor:  designEvent.colorHeadEvent }"
            class="card-header text-center"
            style="align-items: center;"
          >
            <i :style="{ color: designEvent.colorTitleEvent }" class="fa fa-circle f8"></i>
            <span
              :style="{ color: designEvent.colorTitleEvent }"
              class="padding fw-700 f18"
            >{{ $t('eventsView.text2')}}</span>
            <i :style="{ color: designEvent.colorTitleEvent }" class="fa fa-circle f8"></i>
          </div>
          <div class="card-body" v-if="ready_experiences">
            <CardEvent :eventData="item" :key="key" v-for="(item, key) in experiences"></CardEvent>
          </div>
        </div>
        <div
          :style="{ borderColor:  designEvent.colorHeadEvent }"
          class="Eventos-containerCard card"
          v-if="ready_events && ready_experiences"
        >
          <div
            :style="{ backgroundColor:  designEvent.colorHeadEvent }"
            class="card-header text-center"
            style="align-items: center;"
          >
            <i :style="{ color: designEvent.colorTitleEvent }" class="fa fa-circle f8"></i>
            <span
              :style="{ color: designEvent.colorTitleEvent }"
              class="padding fw-700 f18"
            >{{ $t('eventsView.text3')}}</span>
            <i :style="{ color: designEvent.colorTitleEvent }" class="fa fa-circle f8"></i>
          </div>
          <div class="card-body">{{ $t('eventsView.text4')}}</div>
        </div>
      </div>
    </div>
    <FooterEvent :designparams="designEvent"></FooterEvent>
  </div>
</template>
<script>
import globalTypes from '@/store/types/global';
import whiteLabelTypes from '@/store/types/whiteLabel';
import { mapGetters } from 'vuex';
import HeaderEvent from '@/components/Event/header';
import CardEvent from '@/components/Event/cardEvent';
import FooterEvent from '@/components/Event/footer';
import NavBar from '@/components/Select/navBar';

export default {
  components: {
    HeaderEvent,
    FooterEvent,
    CardEvent,
    NavBar
  },
  data () {
    return {
      events: [],
      experiences: [],
      ready_events: false,
      ready_experiences: false
    };
  },
  computed: {
    ...mapGetters({
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    }),
    colorTextEvent () {
      return { color: this.designEvent.colorTitleEvent };
    }
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    const vendorUrl = this.subDomain();
    this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
    this.$store
      .dispatch({ type: 'whiteLabel:vendorId', data: { url: vendorUrl } })
      .then(() => {
        if (this.$store.getters['whiteLabel:vendorId']) {
          this.$store
            .dispatch({
              type: 'whiteLabel:vendor',
              data: { vendorId: this.$store.getters['whiteLabel:vendorId'] }
            })
            .then(data => {
              document.title = data.data.vendor.displayName
                ? data.data.vendor.displayName
                : '';
              document.querySelector("link[rel*='icon']").href =
                (data.data.vendor.photoURL !== null) ? data.data.vendor.photoURL : data.data.vendor.thumbnailURL;
            });
          this.$store
            .dispatch({
              type: 'event:allEvents',
              data: { vendorId: this.$store.getters['whiteLabel:vendorId'], all: true }
            })
            .then(response => {
              this.reduceEvents(response.data);
            });
        }
      });
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  },
  methods: {
    reduceEvents (data) {
      data.forEach(items => {
        if (items.type === 'event') {
          this.events.push(items);
          this.ready_events = true;
        } else {
          this.experiences.push(items);
          this.ready_experiences = true;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.Eventos {
  &-container {
    margin-top: -100px;
    &CardExperience {
      margin-top: 20px;
    }
  }

  &-box {
    width: 80%;
    margin-left: 10%;
  }

  &-title {
    background-color: var(--bgColorStickPay);
    padding: 10px 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colorStickPay);
    i {
      font-size: 8px;
      margin: 0 10px;
    }
  }

  &-body {
    padding: 2%;
    background: #fff;
    border: 1px solid var(--colorHeadEvent);
    padding-bottom: 0;
    margin-bottom: 2%;
  }
}
</style>
